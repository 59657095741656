(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider:not(.last)").owlCarousel({
      items: 6,
      margin: 15,
      loop: true,
      center: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1.8,
        },
        576: {
          items: 3.3,
        },
        768: {
          items: 5.5,
        },
        992: {
          items: 6.5,
        },
        1200: {
          items: 6.5,
        },
      },
    });

    // Last slider home
    $(".slider.last").owlCarousel({
      items: 3,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 3,
        },
        768: {
          items: 3,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // make kasabon in smooth layout sticky
    require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
    $(".w3media-booking-receipt-widget").hcSticky({
      stickTo: ".content-section",
      top: 100,
      responsive: {
        992: {
          disable: true,
        },
      },
    });

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 0);
      }
    });

    // faq fix
    let faqlinks = document.querySelectorAll(".faq-category a");
    faqlinks.forEach((link) => {
      if (link.id) {
        link.id = "";
        link.href = "#";
      }
    });

    // prevent scrolling on #TommyBookingSupport page
    $(window).on("hashchange", function (e) {
      if ($("#TommyBookingSupport").length > 0) {
        e.preventDefault();

        // disable scrolling
        $("body").css("overflow", "hidden");

        // set current scroll position
        $("html, body").animate({
          scrollTop: $("#TommyBookingSupport").offset().top - 120,
        });

        // enable scrolling
        setTimeout(() => {
          $("body").css("overflow", "auto");
        }, 10);
      }
    });
  });
})(jQuery);

// news ticker
// Selecteer ticker-elementen
let ticker = document.querySelector("header .news-ticker ul.news-ticker");
let tickerDiv = document.querySelector("header .news-ticker div.news-ticker");

if (ticker.childElementCount > 1) {
  ticker.classList.add("active-mobile");
}

if (ticker && tickerDiv) {
  


  let tickerWidth = ticker.offsetWidth;

  let windowWidth = window.innerWidth;

  if (tickerWidth > windowWidth) {
    let clone = ticker.cloneNode(true);

    clone.classList.add("active", "clone");

    tickerDiv.appendChild(clone);

    ticker.classList.add("active");
    let speed = ticker.childElementCount * 5;
    // set css var for speed
    tickerDiv.style.setProperty("--news-ticker-speed", speed + "s");
  }
}

// bundle overview offset
function setOffset() {
  let pageClassNames = [".bundle-overview"];

  let pages = [];
  pageClassNames.forEach((classname) => {
    let domPage = document.querySelector(classname);
    if (domPage) {
      pages.push(domPage);
    }
  });

  pages.forEach((page) => {
    if (page) {
      const pageTitleElement = page.querySelector(`.page-title`);
      const wysiwygElement = page.querySelector(`.wysiwyg`);
      const infoElement = page.querySelector("div.info");
      const scndItem = page.querySelectorAll(`.bundle-overview-section .item`);

      let titleHoogte = 0;
      let wysiwygHoogte = 0;
      let infoHoogte = 0;
      if (pageTitleElement) {
        titleHoogte = pageTitleElement.offsetHeight;
      }
      if (wysiwygElement) {
        wysiwygHoogte = wysiwygElement.offsetHeight;
      }
      if (infoElement) {
        infoHoogte = infoElement.offsetHeight;
      }
      const offset = titleHoogte + wysiwygHoogte + infoHoogte - 60;
      
      if (scndItem && !device.mobile()) {
        for (let i = 1; i < scndItem.length; i += 2) {
          if (i == 1) {
            scndItem[i].style.marginTop = `-${offset}px`;
           
          }
          else{
           let previousbottom = scndItem[i-2].getBoundingClientRect().bottom;
           let ownTop = scndItem[i].getBoundingClientRect().top;
           let distancetopreviousbottom = ownTop - previousbottom;
           let margin = distancetopreviousbottom - 20;
            scndItem[i].style.marginTop = `-${margin}px`;
            
          }
        }
      }
    }
  });
}

document.addEventListener("DOMContentLoaded", setOffset);
window.addEventListener("resize", setOffset);
